import { Link } from 'gatsby'
import React from 'react'

const Contact = () => {
    return (
        <div className="com5">
            <div className="iletisim">
                <h3>Bize Ulaşın</h3>
                <div className="iletisimler">
                    <div className="iletisimkutu">
                        <i className="fas fa-map-marked-alt"></i>
                        <Link
                            target="_blank"
                            href="https://www.google.com/maps/place/%C5%9Eafak,+842.+Cd.+No:49,+06830+G%C3%B6lba%C5%9F%C4%B1%2FAnkara/@39.7968371,32.8109027,286m/data=!3m2!1e3!4b1!4m5!3m4!1s0x14d343d762d3d77d:0xa32c389ba2dbee15!8m2!3d39.796835!4d32.811997">
                            Adres: Şafak Mah. 842.Sokak No:49 - Ankara
                        </Link>
                    </div>
                    <div className="iletisimkutu">
                        <i className="fas fa-phone-alt"></i>
                        <Link href="tel:+90-542-103-18-66">Telefon: 0(542) 103 18 66</Link>
                    </div>
                    <div className="iletisimkutu">
                        <i className="fas fa-envelope"></i>
                        <Link href="mailto:info@birucem.com">E-mail: info@birucem.com</Link>
                    </div>
                    <div className="iletisimkutu">
                        <i className="fas fa-clock"></i>
                        <Link href="./iletisim.html">Açık: Pzt - Cmt / 9:00 - 18:00</Link>
                    </div>
                </div>

            </div>
            <div className="sosyallercerceve">
                <h3>Bizi Takip Edin</h3>
                <div className="sosyalkutu">
                    <div className="sosyaller">
                        <Link target="_blank" href="https://www.instagram.com/birucemmuhendislik" className="fab fa-instagram"></Link>
                    </div>
                    <div className="sosyaller">
                        <Link target="_blank" href="https://www.linkedin.com/in/bir%C3%BC%C3%A7em-m%C3%BChendislik-8114b9213/"
                            className="fab fa-linkedin-in"></Link>
                    </div>
                    {/* <div className="sosyaller">
                    <Link target="_blank" href=""
                        className="fab fa-facebook-f"></Link>
                </div> */}
                </div>
            </div>
        </div>
    )
}

export default Contact
