import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"
import Contact from "../components/Contact"
import Seo from "../components/seo"

const Kurumsal = () => {
    return (
        <Layout>
            <Seo title="Kurumsal" />
            <div className="kurumsal">
                <StaticImage
                    src="../images/kurumsal.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="kurumsal"
                    className="üstimg"
                    placeholder={'blurred'}
                />
                <div className="kurumsal-cerceve">
                    <div className="kurumsal-hakkimizda">
                        <h3>Hakkımızda</h3>
                        <h4 className="kurumsal-text">Birüçem Mühendislik, alanlarında uzman dört mühendis tarafından kurulmuş olup
                        proje, taahhüt, uygulama, servis ve bakım alanında çeşitli sektörlerden edindikleri deneyimlerin
                        üzerine koyarak; zaman koşullarına ve teknolojisine uygun, yenilikçi projeler ile faaliyet
                        gösterdiği sektörlerde yerini almıştır. Ana amaç olarak müşteri memnuniyetini ilke edinen Birüçem
                        Mühendislik, çevreye olan duyarlılığı, tükenmekte olan kaynaklarımızı doğru kullanma isteği ve
                        sürdürülebilirliğini sağlamak amacıyla yola çıkmıştır.
                        Birüçem Mühendislik olarak su kaynaklarını en verimli şekilde kullanıp, çevreye zarar vermeden
                        gelecek nesillere aktarmak için çalışıyoruz. Tüm bunların yanı sıra kaynakları sıfır israf mottosu
                        ile kullanıp daha da yaşanılabilir bir dünya için çalışıyoruz.
                        Yaşadığımız ülkemize katma değer katıp, siz değerli paydaşlarımıza sürekli çözümler üretmek Birüçem
                    Mühendislik’in en büyük hedeflerindendir.</h4>
                    </div>
                    <div className="kurumsal-vizyon">
                        <h3>Vizyon</h3>
                        <h4 className="kurumsal-text">Birüçem Mühendislik olarak; kalite felsefesinden ve müşteri memnuniyetinden
                        ödün vermeden, planladığı hedefler doğrultusunda proje, üretim ve uygulama hizmetlerine değer
                        katmayı kendine görev edinen, paydaşlarının beklentilerini en üst düzeyde karşılayıp, faaliyette
                    olduğu sektörlerin en önde gelen firmalarında biri olmaktır.</h4>
                    </div>
                    <div className="kurumsal-misyon">
                        <h3>Misyon</h3>
                        <h4 className="kurumsal-text">Birüçem Mühendislik olarak; güçlü iletişimi ile çalışanlarını ve paydaşlarını
                        aile olarak gören anlayışla, katma değer üretip, çevreye duyarlılığını sürdürerek faaliyet
                    gösterdiği sektörlere değer katmaktır.</h4>
                    </div>
                </div>
            </div>
            <Contact />
        </Layout>
    )
}

export default Kurumsal
